var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return true
    ? _c(
        "Dialog",
        {
          attrs: {
            name: _vm.name,
            width: "526px",
            "class-own": "dialog-delete-timeslot",
          },
          on: {
            close: function ($event) {
              _vm.step = 1
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "400",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.step === 1
                                ? "Удалить тайм слот"
                                : "Подтвердить удаление"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            261780732
          ),
        },
        [
          _vm.step === 1
            ? _c("div", { staticClass: "admin-delete-timeslot__message" }, [
                _vm._v(
                  " Средства за удаленные тайм слоты возвращаются только из-за технических проблем на терминале или квота выставлена ошибочно и только тем, кто еще по факту не выгрузился. "
                ),
                _c("br"),
                _c("br"),
                _c("b", [
                  _vm._v("Вернуть средства за удаляемые ТС бронирующим?"),
                ]),
              ])
            : _c("div", { staticClass: "admin-delete-timeslot__message" }, [
                _vm._v(
                  " Удалив ТС, вам необходимо самостоятельно сократить квоту. Средства за удаленные ТС "
                ),
                _c("b", [_vm._v(_vm._s(_vm.refund ? "" : "не") + " вернутся")]),
                _vm._v(
                  " на баланс бронирующим. Удаление ТС - процесс необратимый. При необходимости вы можете снова увеличить распределенную квоту."
                ),
                _c("br"),
                _c("br"),
                _c("b", [_vm._v("Вы действительно хотите удалить ТС?")]),
              ]),
          _c(
            "div",
            { staticClass: "df jc-sb" },
            [
              _c("iq-mobile-button", {
                attrs: {
                  "button-style":
                    "padding: 12px; width: 100%; margin-right: 4px; background: #F56C6C; border-color: #F56C6C; color: white; cursor: pointer;",
                  "text-style": "color: white; font-weight: 500",
                  title: _vm.step === 1 ? "С возвратом" : "Нет",
                },
                on: {
                  onClick: function ($event) {
                    _vm.step === 1
                      ? _vm.handleRefundTimeslots(true)
                      : _vm.handleRemoveTimeslots(false)
                  },
                },
              }),
              _c("iq-mobile-button", {
                attrs: {
                  "button-style":
                    "padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white; cursor: pointer;",
                  "text-style": "color: white; font-weight: 500",
                  title: _vm.step === 1 ? "Без возврата" : "Да",
                },
                on: {
                  onClick: function ($event) {
                    _vm.step === 1
                      ? _vm.handleRefundTimeslots(false)
                      : _vm.handleRemoveTimeslots(true)
                  },
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }